import axios from "axios";
const queryString = require('query-string');

export default {    
    getItems(pageNumber, requestParam) {
        let queryParams = queryString.stringify(Object.assign({
            querySearch: requestParam.querySearch,
            pageIndex: pageNumber,
            pageSize: requestParam.pageSize,
            orderBy: requestParam.orderBy,
            directionSort: requestParam.directionSort,
            userLevel: requestParam.userLevel,
            notifyStatus: requestParam.notifyStatus,
            homedir: requestParam.homedir,
        }, requestParam.filterItem));

        return axios({
            method: "GET",
            url: `/api/storage-cleaning/get-items?${queryParams}`,
            withCredentials: true,
        });
    },
    sendNotificationItem(requestData){
        return axios({
            method: "POST",
            url: `/api/storage-cleaning/perform-send-notifications-item`,
            data: requestData,
            withCredentials: true,
        });

    },
    deleteItem(requestData){
        return axios({
            method: "POST",
            url: `/api/storage-cleaning/perform-delete-item`,
            data: requestData,
            withCredentials: true,
        });

    }
}