import commonAct from "@/commonActionHandle.js";
import baseComponent from "@/scripts/baseComponent";
import { debounce } from "vue-debounce";
import { mapState } from "vuex";
import zStorageCleaningApi from "@/api/gpuHub/zStorageCleaningApi";
const defaultOrderby = "LastBillingTimestamp";
export default {
  extends: baseComponent,
  props: {},
  watch: {
    selectionModel: {
      handler() {
        if (this.selectionModel.itemsSelected.length === 0 && this.selectionModel.isCheckAll) {
          this.selectionModel.isCheckAll = false;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  components: {},
  data() {
    return {
      isLoading: false,
      debounceFnc: null,
      selectionModel: {
        isCheckAll: false,
        itemsSelected: [],
      },
      elements: {
        querySearch: "",
        userLevel: 0,
        notifyStatus: 1,
        homedir: "",
        data: [],
        pagingItem: {
          pageIndex: 1,
          pageSize: 20,
          numberOfPage: 1,
          outRowsNumber: 0,
          orderBy: defaultOrderby,
          directionSort: "asc",
        },
      },
    };
  },
  mounted() {
    this.getElementsList(1);
    this.$signalR.on("DataItemChanged", (data) => {
      console.log("DataItemChanged", data);
    });
    this.$signalR.start().then(() => {
      console.log("SignalR Connected");
    });
  },
  beforeDestroy() {
    this.$signalR.off("DataItemChanged");
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.auth.user,
    }),
    isIndeterminate() {
      let checkedCount = this.selectionModel.itemsSelected.length;
      let rowsCount = this.elements.data.length;
      return !this.isLoading && this.selectionModel.itemsSelected.length > 0 && checkedCount !== rowsCount;
    },
    defaultPagingItem() {
      return {
        pageIndex: 1,
        pageSize: 20,
        numberOfPage: 1,
        outRowsNumber: 0,
        orderBy: defaultOrderby,
        directionSort: "desc",
      };
    },
    requestParam() {
      return {
        querySearch: this.elements.querySearch,
        pageSize: this.elements.pagingItem.pageSize,
        orderBy: this.elements.pagingItem.orderBy,
        directionSort: this.elements.pagingItem.directionSort,
        userLevel: this.elements.userLevel,
        notifyStatus: this.elements.notifyStatus,
        homedir: this.elements.homedir,
      };
    },
    pageCount() {
      try {
        return Math.ceil(this.elements.pagingItem.outRowsNumber / this.elements.pagingItem.pageSize);
      } catch {
        return 0;
      }
    },
  },
  created() {
    this.debounceFnc = debounce(() => {
      this.getElementsList(1);
    }, 1000);
  },
  methods: {
    sortChanged(data) {
      this.$set(this.elements.pagingItem, "orderBy", data.orderBy);
      this.$set(this.elements.pagingItem, "directionSort", data.directionSort);
      this.pageClickHandle(1);
    },
    getElementsList(pageNumber) {
      if (this.selectionModel.itemsSelected.length > 0) this.selectionModel.itemsSelected = [];
      this.showLoading();
      zStorageCleaningApi
        .getItems(pageNumber, this.requestParam)
        .then((response) => {
          if (response.data && response.data.result === 0 && response.data.data !== null) {
            this.elements.data = response.data.data.data;
            this.elements.pagingItem = {
              ...response.data.data.pagination,
              orderBy: this.elements.pagingItem.orderBy,
              directionSort: this.elements.pagingItem.directionSort,
            };
            this.$nextTick(() => {
              this.hideLoading();
            });
          } else {
            this.elements.data = [];
            this.elements.pagingItem = this.defaultPagingItem;
            this.$nextTick(() => {
              this.hideLoading();
            });
            if (response.data && response.data.message !== null && response.data.message !== "") {
              commonAct.showError(response.data.message || this.$lang.common.error);
            }
          }
        })
        .catch((error) => {
          console.error(error);
          this.elements.data = [];
          this.elements.pagingItem = this.defaultPagingItem;
          this.$nextTick(() => {
            this.hideLoading();
          });
          commonAct.showError(error);
        });
    },
    pageClickHandle(pageNumber) {
      this.$store.dispatch("CANCEL_PENDING_REQUESTS");
      this.getElementsList(pageNumber);
    },
    refreshClick() {
      this.$store.dispatch("CANCEL_PENDING_REQUESTS");
      this.elements.querySearch = "";
      this.elements.userLevel = 0;
      //this.elements.notifyStatus = 1;
      this.elements.homedir = "";
      this.getElementsList(1);
    },
    onSearchInput(isDebounce) {
      if (isDebounce) {
        if (this.debounceFnc) this.debounceFnc();
      } else {
        this.getElementsList(1);
      }
    },
    onWaypoint({ element }) {},
    handleCheckAllChange() {
      if (!this.selectionModel.isCheckAll) {
        this.selectionModel.itemsSelected = [];
      } else {
        this.selectionModel.itemsSelected = this.elements.data.map((x) => x.userId);
      }
    },
    handleCheckedItemChange() {},
    sendNotificationItem(requestData) {
      this.showLoading();
      zStorageCleaningApi
        .sendNotificationItem(requestData)
        .then((response) => {
          if (response.data && response.data.result === 0) {
            this.$message({
              type: "success",
              message: "Perform action completed",
            });
            this.$nextTick(() => {
              this.getElementsList(1);
            });
          } else {
            this.$nextTick(() => {
              this.hideLoading();
            });
            if (response.data && response.data.message !== null && response.data.message !== "") {
              this.$message.error(`Oops, ${response.data.message || this.$lang.common.error}`);
            }
          }
        })
        .catch((error) => {
          console.error(error);
          this.$nextTick(() => {
            this.hideLoading();
          });
          this.$message.error("Oops, somethings went wrong.");
        });
    },
    deleteItem(requestData) {
      zStorageCleaningApi
        .deleteItem(requestData)
        .then((response) => {
          if (response.data && response.data.result === 0) {
            this.$message({
              type: "success",
              message: "Perform action completed",
            });
            this.$nextTick(() => {
              this.getElementsList(1);
            });
          } else {
            this.$nextTick(() => {
              this.hideLoading();
            });
            if (response.data && response.data.message !== null && response.data.message !== "") {
              this.$message.error(`Oops, ${response.data.message || this.$lang.common.error}`);
            }
          }
        })
        .catch((error) => {
          console.error(error);
          this.$nextTick(() => {
            this.hideLoading();
          });
          this.$message.error("Oops, somethings went wrong.");
        });
    },
    performanceAction(itemSelected, action) {
      const actionName = action === "SendNotification" ? `Send notification to ${itemSelected.username}` : `delele ${itemSelected.username}'s Z data`;
      this.$confirm(`Perform action ${actionName}. Continue?`, "Warning", {
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(() => {
          const request = { UsersSelected: [itemSelected.userId] };
          switch (action) {
            case "SendNotification":
              this.sendNotificationItem(request);
              break;
            case "Delete":
              this.deleteItem(request);
              break;
          }
        })
        .catch(() => {});
    },
    performanceActionOnSelection(action) {
      const actionName = action === "SendNotification" ? `Send notification to` : `delele Z data on`;
      this.$confirm(`Perform action ${actionName} selected users. Continue?`, "Warning", {
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(() => {
          const request = { UsersSelected: this.selectionModel.itemsSelected };
          switch (action) {
            case "SendNotification":
              this.sendNotificationItem(request);
              break;
            case "Delete":
              this.deleteItem(request);
              break;
          }
        })
        .catch(() => {});
    },
  },
};
